<template>
  <div class="mb-4">
    <form
      class="d-flex justify-content-end flex-lg-row flex-column"
      style="position: relative; align-items: center"
      @submit="load"
    >
      <div
        class="col-xl-4 col-lg-6 col-12 d-flex flex-column align-items-center"
      >
        <div class="col-lg-8 col-12">
          <select
            v-model="eventDATA"
            name="events"
            class="form-control"
            id="select_event"
          >
            <option
              value=""
              :selected="!event_selected || event_selected === 0"
              disabled
            >
              Selecione um evento
            </option>
            <option
              v-for="(evento, index) in eventsJSON"
              :key="index"
              :value="evento.id"
              :selected="event_selected == evento.id || false"
            >
              {{ evento.year }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-12 d-flex flex-column">
        <!-- CAMPO DE BUSCA -->
        <div class="icon-change alter-mouse">
          <input
            type="text"
            class="form-control"
            style="
              border-bottom-left-radius: 0px;
              border-bottom-right-radius: 0px;
            "
            name="search"
            id="search"
            :value="search"
            minlength="3"
            placeholder="Buscar Credenciais"
          /><button
            type="submit"
            id="search_button"
            style="display: flex !important; border: none; padding: 0"
          >
            <i class="fa fa-search"></i>
          </button>
        </div>

        <!-- TOTAL DE BUSCA -->
        <div class="d-flex justify-content-around found-items w-auto">
          <span style="padding: 2px"
            >Carrinhos: <b>{{ found_carts }}</b></span
          >
          <span style="padding: 2px"
            >Ingressos: <b>{{ found_ticket }}</b></span
          >
        </div>
        <div id="preencher" style="display: none">
          <span style="color:red">Verifique se os campos estão preenchidos!</span>
        </div>
      </div>

      <!-- LISTA A DIREITA -->
      <div
        class="col-xl-4 col-lg-6 col-12 align-items-center"
        style="display: flex; flex-direction: column"
        id="totalizadores"
      >
        <button
          type="button"
          style="text-align: center; width: fit-content"
          class="mt-3 mb-2 btn btn-info btn-sm"
          data-toggle="collapse"
          data-target="#totalizadores_anos"
          aria-expanded="true"
          aria-controls="totalizadores_anos"
        >
          <b>Totalizadores</b>
        </button>
        <div
          id="totalizadores_anos"
          class="collapse w-100 row"
          aria-labelledby="headingOne"
          data-parent="#totalizadores"
        >
          <div
            v-for="(coluna, i) in checkinsJSON"
            :key="i"
            class="col-4 d-flex flex-column align-items-center p-0"
          >
            <div
              v-for="(ano, index) in coluna"
              :key="index"
              class="mt-2 row"
              style="width: 100%"
            >
              <div class="col-12">
                <button
                  type="button"
                  style="text-align: center"
                  class="btn btn-primary btn-sm w-100"
                  data-toggle="collapse"
                  :data-target="'#totalizadores_' + ano.id"
                  aria-expanded="true"
                  :aria-controls="'totalizadores_' + ano.id"
                >
                  {{ ano.year }}
                </button>
                <div
                  class="collapse"
                  :data-parent="'#totalizadores_anos'"
                  :id="'totalizadores_' + ano.id"
                >
                  <span
                    >Check-ins:
                    <b style="color: #28a745">{{ ano.checkins }}</b></span
                  >
                  <br />
                  <span
                    >Credenciados: <b>{{ ano.credenciados }}</b></span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: [
    "search",
    "checkins",
    "found_carts",
    "found_ticket",
    "events",
    "event_selected",
  ],
  data() {
    return {
      eventDATA: "",
      checkinsJSON: {},
      eventsJSON: {},
    };
  },

  created() {
    this.checkinsJSON = JSON.parse(this.checkins);
    this.eventsJSON = JSON.parse(this.events);   
    if (this.event_selected) {
      this.eventDATA = this.event_selected;
    }     
  },

  methods: {
    load(e) {
      let search_value = document.getElementById("search").value;
      document.getElementById("search_button").disabled = true;

      if (!search_value || !this.eventDATA) {
        document.getElementById("preencher").style.display = "flex";
        document.getElementById("search_button").disabled = false;
        e.preventDefault();
      }else{
        document.getElementById("preencher").style.display = "none";
      }

    },
  },
};
</script>
<style lang="scss" scoped>
.icon-change {
  position: relative;
  i,
  button {
    position: absolute;
    top: 50%;
    right: -8.8px;
    transform: translate(-50%, -50%);
  }
  i {
    padding: 10px 10px 10px 10px;
    background: #eee;
    border-left: 2px solid #eee;
    border-radius: 0.25rem;
  }
}

.found-items {
  width: 450px;
  border-right: 1px solid #ced4da;
  margin-top: 0 !important;
  border-left: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #eee;
}
</style>
