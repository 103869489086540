<template>
  <div>
    <div
      style="cursor: pointer !important"
      class="icon-change"
      v-on:click="Alter()"
    >
      <input
        type="text"
        name="email"
        class="form-control"
        id="email"
        :value="email"
        style="cursor: pointer !important"
        readonly
      />
      <i class="fa fa-pencil"></i>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="modal_email"
      tabindex="-1"
      aria-labelledby="modal_emailLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modal_emailLabel">
              Alteração de E-mail
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div>
              <div class="row justify-content-center mb-4">
                <div class="col-8 d-flex justify-content-center">
                  <span style="min-width: 100px; align-self: center"
                    >Email Atual:</span
                  >
                  <input
                    style="text-align: center"
                    class="form-control"
                    :value="email"
                    disabled
                  />
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-8 d-flex justify-content-center">
                  <span style="min-width: 100px; align-self: center"
                    >Novo Email:</span
                  >
                  <input
                    :style="error_msg ? 'border-color:#dd4b39;' : ''"
                    style="text-align: center"
                    class="form-control"
                    v-model="new_email"
                  />
                </div>
              </div>

              <div v-if="error_msg" class="row justify-content-center">
                <div class="col-8 d-flex justify-content-center">
                  <span style="min-width: 100px; align-self: center"></span>
                  <span style="color: #dd4b39">{{ error_msg }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-center">
            <div>
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-secondary"
              >
                Cancelar
              </button>
            </div>
            <div>
              <button type="button" class="btn btn-primary" @click="Verify()">
                <i
                  :class="loading ? 'fa fa-spinner fa-pulse fa-fw' : ''"
                  style="width: 133.81px"
                  >{{ !loading ? "Confirmar Alteração" : "" }}</i
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
export default {
  props: ["email", "verify_email_endpoint"],
  data() {
    return {
      new_email: "",
      error_msg: "",
      loading: false,
    };
  },
  methods: {
    Alter() {
      $("#modal_email").modal("show");
    },
    Verify() {
      this.loading = true;
      Axios.post(this.verify_email_endpoint, {
        email: this.new_email,
        old_email: this.email
      })
        .then((response) => {
          this.$swal({
                title: response.data,
                timer: 500,
                type: "success",
                showConfirmButton: false,
              }).then(()=>{
              location.reload();
          });
        })
        .catch((error) => {
          console.log(error.response.data.errors.email[0]);
          this.error_msg = error.response.data.errors.email[0];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.icon-change {
  position: relative;
  i {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
  }
}
</style>
