<template>
  <div class="box box-widget">
    <div class="box-header">
      <form method="GET" class="form-horizontal" :action="url">
        <div class="row justify-content-between" style="padding: 15px; padding-bottom: 5px !important">
          <div style="align-self: center">
            <h3 class="box-title">{{ title }}</h3>
          </div>
          <div style="align-self: center">
            <span
              >Resultados Encontrados: <b>{{ total_search ? total_search : 0 }}</b></span
            >
          </div>
          <div class="input-group" style="width: auto">
            <input type="text" name="busca" class="form-control" placeholder="Buscar por" :value="busca" />
            <span class="input-group-btn">
              <button type="submit" class="btn btn-primary btn-flat">
                <i class="fa fa-search"></i>
              </button>
              <a :href="url" class="btn btn-default btn-flat" data-toggle="tooltip" title="Limpar Busca">
                <i class="fa fa-list"></i>
              </a>
              <button
                type="button"
                class="btn btn-success btn-flat"
                :disabled="loadingExport"
                data-toggle="tooltip"
                @click="exportSales()"
                title="Exportar"
              >
                <i class="fa fa-table"></i>
              </button>
            </span>
          </div>
        </div>
        <div class="row selects_group" style="padding-top: 15px">
          <div class="cols-12 col-md-6 col-lg-3">
            <select class="form-control" name="accreditation_select" id="accreditation_select" :selected="2">
              <option value="" :selected="!accreditation_selected || accreditation_selected == 0">Status Credenciamento</option>
              <option :value="1" :selected="accreditation_selected == 1 || false">Sem Credenciamento</option>
              <option :value="2" :selected="accreditation_selected == 2 || false">Credenciados</option>
            </select>
          </div>

          <div class="cols-12 col-md-6 col-lg-3">
            <select class="form-control" name="state_select" id="state_select">
              <option value="" :selected="!state_selected || state_selected == 0">Selecionar Estado</option>
              <option
                v-for="(option, index) in JSON.parse(state_options)"
                :key="index"
                :value="option.value"
                :selected="state_selected == option.value || false"
              >
                {{ option.label }}
              </option>
            </select>
          </div>

          <div class="cols-12 col-md-6 col-lg-3">
            <select class="form-control" name="city_select" id="city_select">
              <option value="" :selected="!city_selected || city_selected == 0">Selecionar Cidade</option>
              <option
                v-for="(option, index) in JSON.parse(city_options)"
                :key="index"
                :value="option.value"
                :selected="city_selected == option.value || false"
              >
                {{ option.label }}
              </option>
            </select>
          </div>

          <div class="cols-12 col-md-6 col-lg-3">
            <select class="form-control" name="gender_select" id="gender_select">
              <option value="" :selected="!gender_selected || gender_selected == 0">Selecionar Gênero</option>
              <option value="masculino" :selected="gender_selected == 'masculino' || false">Masculino</option>
              <option value="feminino" :selected="gender_selected == 'feminino' || false">Feminino</option>
            </select>
          </div>

          <div class="cols-12 col-md-6 col-lg-3">
            <select class="form-control" name="ticket_select" id="ticket_select">
              <option value="" :selected="!ticket_selected || ticket_selected == 0">Selecionar o Passaporte</option>
              <option
                v-for="(option, index) in JSON.parse(tickets_options)"
                :key="index"
                :value="option.value"
                :selected="ticket_selected == option.value || false"
              >
                {{ option.label }}
              </option>
            </select>
          </div>

          <div class="cols-12 col-md-6 col-lg-3">
            <select class="form-control" name="step_select" id="step_select">
              <option value="" :selected="!step_selected || step_selected == 0">Selecionar Etapa do Carrinho</option>
              <option
                v-for="(option, index) in JSON.parse(step_options)"
                :key="index"
                :value="option.value"
                :selected="step_selected == option.value || false"
              >
                {{ option.label }}
              </option>
            </select>
          </div>

          <div class="cols-12 col-md-6 col-lg-3">
            <select class="form-control" name="sale_type_select" id="sale_type_select">
              <option value="" :selected="!sale_type_selected || sale_type_selected == 0">Selecionar Tipo de Venda</option>
              <option value="DIRECT_SELL" :selected="sale_type_selected == 'DIRECT_SELL' || false">Venda Direta</option>
              <option value="SITE" :selected="sale_type_selected == 'SITE' || false">Site</option>
            </select>
          </div>

          <div class="cols-12 col-md-6 col-lg-3">
            <select class="form-control" name="year_select" id="year_select">
              <option value="" :selected="!year_selected || year_selected == 0">Selecionar Evento</option>
              <option
                v-for="(option, index) in JSON.parse(year_options)"
                :key="index"
                :value="option.value"
                :selected="year_selected == option.value || false"
              >
                {{ option.label }}
              </option>
            </select>
          </div>

          <div class="row cols-12 col-md-6" style="text-align: center">
            <div
              class="cols-12 col-md-6 col-lg-4"
              style="padding: 15px"
              data-trigger="hover"
              data-toggle="popover"
              data-placement="bottom"
              title="Primeiro Termo"
              data-content="Autorizo a coleta de meus dados pessoais solicitados no momento em que realizo meu check-in no Evento. Esses dados são importantes para evitarmos eventuais fraudes e para conseguirmos entregar uma melhor experiência ao participante."
            >
              <input type="checkbox" name="check1" id="check1" :checked="check1_selected ? true : false" />
              <label for="check1">Termos 1 </label>
            </div>
            <div
              class="cols-12 col-md-6 col-lg-4"
              style="padding: 15px"
              data-trigger="hover"
              data-toggle="popover"
              data-placement="bottom"
              title="Segundo Termo"
              data-content="Autorizo o uso e tratamento de minha imagem que poderá ser divulgada em redes sociais e plataformas de streaming por um período de até 5 anos."
            >
              <input type="checkbox" name="check2" id="check2" :checked="check2_selected ? true : false" />
              <label for="check2">Termos 2 </label>
            </div>
            <div
              class="cols-12 col-md-6 col-lg-4"
              style="padding: 15px"
              data-trigger="hover"
              data-toggle="popover"
              data-placement="bottom"
              title="Terceiro Termo"
              data-content="Autorizo receber conteúdos de parceiros e patrocinadores do Evento antes, durante e após o evento."
            >
              <input type="checkbox" name="check3" id="check3" :checked="check3_selected ? true : false" />
              <label for="check3">Termos 3 </label>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="box-body" :style="total_search && total_search > 0 ? 'width: 100%;overflow-x: scroll;' : 'overflow-x:hidden'" @mousewheel="scrollX()">
      <form v-if="items.data.length > 0" :class="formClass" v-on:submit.prevent="confirmDelete" :action="url + '/destroy'" method="POST">
        <input type="hidden" name="_token" :value="token" />
        <input type="hidden" name="_method" value="DELETE" />
        <table id="example1" class="table table-bordered table-striped">
          <thead style="width: 100%; overflow-x: scroll">
            <tr>
              <th v-if="!notDeletable" align="center" width="50px">
                <input
                  class="checkbox-delete"
                  id="checkbox-delete-origin"
                  type="checkbox"
                  name="registro[]"
                  data-toggle="tooltip"
                  title="Marcar tudo"
                  v-on:click="checkAll"
                />
              </th>
              <th v-for="(title, index) in titles" :key="index">{{ title }}</th>
              <th v-if="actions != 'false'">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items.data" :key="index">
              <th v-if="!notDeletable" align="center" class="v-middle">
                <input class="checkbox-delete" type="checkbox" name="registro[]" :value="item.id" />
              </th>
              <td v-for="(field, index) in item" :key="index" class="v-middle">
                <span v-if="field === null">{{ field }}N/A</span>
                <span v-else-if="!field.type && !Array.isArray(field)">{{ field }}</span>
                <span
                  class="mr-2 px-2 py-0 rounded-pill badge"
                  v-else-if="isMultiBadge(field)"
                  v-for="(campo, index) in field"
                  :key="index"
                  :class="'badge-' + campo.status"
                >
                  <span>{{ campo.text }}</span>
                </span>
                <span v-else-if="field.type == 'img'">
                  <img :src="field.src" style="max-width: 150px; max-height: 150px" />
                </span>

                <span v-else-if="field.type == 'badge'" class="badge" :class="'badge-' + field.status">{{ field.text }}</span>

                <span v-else-if="field.type == 'action'" v-for="(action, index) in item.actions" :key="index">
                  <a data-toggle="tooltip" class="btn btn-flat ml-10" :class="'btn-' + action.color" :href="action.path" :title="action.label">
                    <i :class="action.icon"></i> </a
                  >&nbsp;
                </span>
              </td>
              <td v-if="!item.actions && actions != 'false' && !comment && !qrcode" class="v-middle">
                <a class="btn btn-flat btn-primary" :href="url + '/' + item.id + '/edit'" data-toggle="tooltip" title="Editar">
                  <i class="fa fa-pencil"></i>
                </a>
              </td>
              <td v-if="comment" class="v-middle">
                <a
                  class="btn btn-flat btn-success"
                  @click="actionComment(url + '/approve/' + item.id)"
                  data-toggle="tooltip"
                  title="Aprovar"
                  v-if="item.aprovado.text == 'Reprovado'"
                >
                  <i class="fa fa-check"></i>
                </a>
                <a
                  class="btn btn-flat btn-danger"
                  @click="actionComment(url + '/disapprove/' + item.id)"
                  data-toggle="tooltip"
                  title="Reprovar"
                  v-else
                >
                  <i class="fa fa-ban"></i>
                </a>
              </td>

              <td v-if="qrcode" class="v-middle">
                <a class="btn btn-flat btn-dark" @click="geraQrCode(item.codigo_qrcode)" data-toggle="tooltip" title="Gerar QRCode">
                  <i :id="item.codigo_qrcode" class="fa fa-qrcode"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="titles.length + 2" align="left" vertical-align="center">
                <button v-if="!notDeletable" type="submit" class="mv-22 btn btn-flat btn-danger" :disabled="btnDelete.disabled">
                  <i :class="btnDelete.icon"></i>
                  {{ btnDelete.text }}
                </button>
              </td>
            </tr>
          </tfoot>
        </table>
      </form>
      <div v-if="items.data.length == 0" class="callout callout-default">
        <h4>Nenhum registro encontrado!</h4>
      </div>
    </div>
    <slot name="pagination"></slot>
  </div>
</template>

<style lang="scss">
.mv-22 {
  margin: 22px 0;
}
.v-middle {
  vertical-align: middle !important;
}

.tab-content {
  padding-bottom: 30px !important;
}

.selects_group > div {
  padding-bottom: 10px;
}

.selects_group > div > select > option:first-child {
  font-weight: bolder;
}
</style>

<script>
import axios from "axios";
export default {
  props: [
    "title",
    "titles",
    "items",
    "busca",
    "url",
    "token",
    "formClass",
    "notDeletable",
    "actions",
    "comment",
    "qrcode",
    "accreditation_selected",
    "gender_selected",
    "sale_type_selected",
    "year_options",
    "year_selected",
    "tickets_options",
    "ticket_selected",
    "step_options",
    "step_selected",
    "check1_selected",
    "check2_selected",
    "check3_selected",
    "state_selected",
    "city_selected",
    "state_options",
    "city_options",
    "total_search",
    "raw_query",
  ],
  data: function () {
    return {
      deleteItems: [],
      btnDelete: {
        icon: "fa fa-trash",
        text: "Excluir registros selecionados",
        disabled: false,
      },
      gerarQr: false,
      loadingQr: false,
      loadingExport: false,
    };
  },
  created() {
    if (!this.items.data) {
      this.items.data = this.items;
    }
  },
  methods: {
    scrollX() {
      const scrollContainer = document.querySelector(".box-body");

      scrollContainer.addEventListener("wheel", (evt) => {
        evt.preventDefault();
        scrollContainer.scrollLeft += evt.deltaY;
      });
    },

    isMultiBadge(field) {
      if (Array.isArray(field) && field.length > 0) {
        if (field[0].type == "multiBadge") {
          return true;
        }
      }
      return false;
    },
    checkAll: function () {
      var checkboxOrigin = document.getElementById("checkbox-delete-origin");
      var checkboxes = document.getElementsByClassName("checkbox-delete");
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = checkboxOrigin.checked;
      }
    },
    confirmDelete: function (e) {
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente excluir os registros selecionados?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim, deletar!",
        cancelButtonText: "Não!",
      }).then((result) => {
        if (result.value) {
          e.target.submit(), (this.btnDelete.icon = "fa fa-spinner fa-pulse");
          this.btnDelete.text = "Excluindo";
          this.btnDelete.disabled = true;
        }
      });
    },
    actionComment: function (url) {
      this.$swal({
        title: "Atenção!",
        text: "Deseja realmente alterar este registro?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008d4c",
        cancelButtonColor: "#d4d4d4",
        confirmButtonText: "Sim, alterar!",
        cancelButtonText: "Não!",
      }).then((result) => {
        if (result.value) {
          window.location.href = url;
        }
      });
    },

    geraQrCode: function (codigo) {
      $("#" + codigo)
        .removeClass("fa fa-qrcode")
        .addClass("fa fa-spinner fa-pulse fa-fw");

      axios
        .get("gera-qrcode/" + codigo)
        .then((res) => {
          let file = "gera-qrcode/" + codigo;
          let link = document.createElement("a");
          link.href = file;
          link.download = "QR_" + codigo + ".svg";
          link.target = "_blank";
          link.click();

          $("#" + codigo)
            .removeClass("fa fa-spinner fa-pulse fa-fw")
            .addClass("fa fa-qrcode");
        })
        .catch((err) => {
          this.loadingQr = false;
        });
    },

    exportSales() {
      this.loadingExport = true;

      const FileDownload = require("js-file-download");
      axios
        .post("/api/salesExport", { titles: this.titles, query: this.raw_query })
        .then((res) => {
          FileDownload(res.data, "vendas_detalhadas.csv");
          this.loadingExport = false;
        })
        .catch((err) => {
          console.error(err);
          this.loadingExport = false;
        });
    },
  },
};
</script>