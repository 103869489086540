<template>
  <div>
    <div class="form-group row">
      <label :for="id" class="col-sm-2 control-label">Fluxo de Compras*</label>
      <div class="col-sm-6">
        <select
          class="form-control"
          :name="name"
          :id="id"
          :required="required"
          @change="listKeys($event)"
        >
          <option disabled value="" :selected="!selected || selected == 0">
            Selecione
          </option>
          <option
            v-for="(option, index) in options"
            :key="index"
            :value="option.value"
            :selected="selected == option.value || false"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 control-label" for="tags">Tags</label>
      <div class="col-sm-6">
        <div
          v-if="keyArray.length > 0"
          class="tags-area col-12 d-flex justify-content-around"
          name="tags"
          id="tags"
        >
          <span
            class="tag-border"
            v-for="(item, i) in keyArray"
            :key="i"
            @click="appendText(item)"
            >{{ item }}</span
          >
        </div>
        <div v-if="keyArray.length > 0" class="help-block">
          Clique na tag para adicioná-la ao corpo.
        </div>
        <div v-else class="tags-area col-12 d-flex justify-content-center">
          <strong>Selecione um fluxo</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["name", "id", "required", "options", "selected"],
  data() {
    return {
      keyArray: [],
      previousSelect: "",
    };
  },
  created() {
    this.listKeys(null, this.selected);
    this.previousSelect = this.selected;
  },
  mounted() {
    this.verifyUniqueFlux(this.selected);
  },
  methods: {
    listKeys(event, manual = null) {
      const value = event ? +event.target.value : +manual;
      if (event && this.previousSelect) {
        this.$swal({
          title: "Atenção!",
          text: "Deseja realmente alterar o flux selecionado? A descrição será perdida",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#008d4c",
          cancelButtonColor: "#d4d4d4",
          confirmButtonText: "Sim, alterar!",
          cancelButtonText: "Não!",
        }).then((result) => {
          if (result.value) {
            this.previousSelect = event.target.value;
            this.fillArray(value);
            tinymce.get("simple_textarea").setContent("");
          } else {
            event.target.value = this.previousSelect;
            this.verifyUniqueFlux(+event.target.value);
          }
        });
      } else if (event && !this.previousSelect) {
        this.previousSelect = event.target.value;
        this.fillArray(value);
      } else {
        this.fillArray(value);
      }

      this.verifyUniqueFlux(value);
    },
    verifyUniqueFlux(value) {
      let event_input = document.getElementById("event_id");
      if (value == 6) {
        event_input.disabled = true;
        event_input.selectedIndex = 0;
      } else {
        event_input.disabled = false;
      }
    },
    fillArray(value) {
      switch (value) {
        case 1:
          this.keyArray = ["nome_cliente"];
          break;
        case 2:
          this.keyArray = ["nome_cliente"];
          break;
        case 3:
          this.keyArray = ["nome_cliente", "lista_codigos"];
          break;
        case 4:
          this.keyArray = ["nome_cliente"];
          break;
        case 5:
          this.keyArray = ["nome_cliente", "codigo_passaporte"];
          break;
        case 6:
          this.keyArray = ["nome_cliente", "link_recuperacao_senha"];
          break;
        case 7:
          this.keyArray = [
            "nome_remetente",
            "codigo_passaporte",
          ];
          break;
        default:
          this.keyArray = [];
          break;
      }
    },
    appendText(text) {
      text = "<span>#" + text + "#</span>";
      tinymce
        .get("simple_textarea")
        .execCommand("mceInsertContent", false, text);
    },
  },
};
</script>

<style lang="scss" scoped>
.tags-area {
  border: 1px solid #e6e6e6;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
}

.tag-border {
  font-size: 20px;
  border: 1px solid #6e6e6e;
  padding-inline: 4px;
  border-radius: 7px;
  background-color: #6e6e6e6b;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  &:hover {
    background-color: #4f4a4a;
    color: white;
  }
}
</style>