<template>
  <div>
    <div class="form-group row">
      <label for="event_id" class="col-sm-2 control-label">Evento*</label>
      <div class="col-sm-6">
        <!-- <select
          :options="eventList"
          selected=""
          id="event_id"
          required="true"
          name="event_id"
        >
        </select> -->
        <select
          class="form-control"
          name="event_id"
          id="event_id"
          required="true"
          @change="changeSelect($event)"
        >
          <option value="" :selected="!selected_single || selected_single == 0">
            Selecione
          </option>
          <option
            v-for="(option, index) in eventList"
            :key="index"
            :value="option.value"
            :selected="selected_single == option.value || false"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label for="ticketIds" class="col-sm-2 control-label">Passaportes*</label>
      <div class="col-sm-6">
        <multiselect
          v-model="inputValue"
          :tagPlaceholder="tagplacehold"
          :placeholder="placeholder"
          :name="name"
          id="ticket_multiselect"
          label="name"
          track-by="id"
          :options="ticketList"
          :multiple="multiple"
          :taggable="false"
          :close-on-select="false"
          selectLabel="Pressione Enter para selecionar"
          deselectLabel="Pressione Enter para remover"
          @input="selector"
          :disabled="eventSelected ? false : true"
        ></multiselect>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  components: { Multiselect },
  props: [
    "name",
    "options",
    "placeholder",
    "multiple",
    "tagplacehold",
    "selected",
    "inputid",
    "events",
    "selected_single",
  ],
  data() {
    return {
      inputValue: [],
      eventList: [],
      eventSelected: "",
      ticketList: [],
    };
  },
  created() {
    this.eventSelected = this.selected_single;
    this.eventList = this.events;
    this.ticketList = this.options;
    this.filterSelect();
  },
  methods: {
    changeSelect(e) {
      this.eventSelected = e.target.value;
      if (this.eventSelected) {
        this.filterSelect();
      }
      this.inputValue = [];
    },
    filterSelect() {
      this.ticketList = this.options.filter(
        (item) => item.event_id == this.eventSelected
      );
    },
    selector() {
      document.getElementById(this.inputid).value = JSON.stringify(
        this.inputValue
      );
    },
  },
  mounted() {
    this.inputValue = this.selected;
    this.selector();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
