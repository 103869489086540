import { render, staticRenderFns } from "./DataTableDetailedSales.vue?vue&type=template&id=5c0dbf23&"
import script from "./DataTableDetailedSales.vue?vue&type=script&lang=js&"
export * from "./DataTableDetailedSales.vue?vue&type=script&lang=js&"
import style0 from "./DataTableDetailedSales.vue?vue&type=style&index=0&id=5c0dbf23&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports